//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Tags } from '@/services/api.service';

export default {
  name: 'TagComponent',
  props: ['errors', 'event', 'is-editing'],
  components: {},
  data() {
    return {
      tags: null,
      tagList: null,
    };
  },
  methods: {
    filterFn(val, update) {
      if (this.tagList !== null) {
        // already loaded
        return update();
      }

      Tags.list().then((res) => {
        if (
          res.data &&
          res.data.data &&
          res.data.data.getTags &&
          res.data.data.getTags.length
        ) {
          update(() => {
            this.tagList = res.data.data.getTags;
          });
        } else {
          update(() => {
            this.tagList = [];
          });
        }
      });
      // setTimeout(() => {
      //   update(() => {
      //     this.options = stringOptions
      //   })
      // }, 2000)
    },

    abortFilterFn() {
      // console.log('delayed filter aborted')
    },

    prefillTags() {
      Tags.list().then((res) => {
        if (
          res.data &&
          res.data.data &&
          res.data.data.getTags &&
          res.data.data.getTags.length
        ) {
          this.tagList = res.data.data.getTags;
        } else {
          this.tagList = [];
        }
      });
    },

    toggleTag(tag) {
      if (!this.event.tags) return;
      let exists = this.event.tags.filter((i) => i.id === tag.id).length;
      if (exists) {
        this.event.tags = this.event.tags.filter((i) => i.id !== tag.id);
      } else {
        this.event.tags.push(tag);
      }
    },

    isChecked(tag) {
      if (!this.event.tags) return false;
      return (
        this.event.tags.filter((i) => {
          return i.id === tag.id;
        }).length === 1
      );
    },
  },
  computed: {
    tagListLanguage() {
      if (!this.tagList || !this.tagList.length) return [];
      return this.tagList.filter(
        (i) => i.category.toLowerCase() === 'language'
      );
    },
    tagListTeaching() {
      if (!this.tagList || !this.tagList.length) return [];
      return this.tagList.filter(
        (i) => i.category.toLowerCase() === 'teaching'
      );
    },
  },
  watch: {
    'event.tags'(newval, oldval) {
      if (newval === null) {
        this.event.tags = oldval;
      }
    },
  },
  mounted() {
    this.prefillTags();
  },
  created() {},
};
